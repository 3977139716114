import React from 'react';
import './style.css';
import { asEntity } from 'Hoc';
import { observer } from 'mobx-react';
import { decorate, observable } from 'mobx';
import { Link } from 'react-router-dom';
import { classes } from './style';
import { CSSTransition } from 'react-transition-group';

class Login extends React.Component {
    alertMessage = null;
    message = null;
    handleChange = ev => {
        this.setState({
            ...this.state,
            [ev.target.name]: ev.target.value
        });
    };
    entityDidCatch = (data) => {
        this.message = null;
        this.alertMessage = data;
        console.log(data);  
    };
    entityDidPosted = (token) => {
        console.log(token);
        if (token){
            this.message = null;
            console.log(token);
            this.successMessage = 'Welcome Back !';
            this.alertMessage = null;
            console.log(this.props);
            console.log('alooha');
            setTimeout(() => {
                return <Link to={{ pathname: '/Edit_Admission' }} />;
            }, 3000);   
        }
        
        
    };
    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    onSubmit = ev => {
        ev.preventDefault();
        console.log(this.state);
        if (this.state) {
            const email = this.state.email;
            const password = this.state.password;
            if (email && password) {    
                if (this.validateEmail(email))
                   {this.message = 'Please Wait ...'; this.props.entityStore.post(this.state);}
                else this.alertMessage = 'The email must be a valid email address.';
            }
            else this.alertMessage = 'Please fill in email and password';
        }
        else this.alertMessage = 'Please fill in email and password';
    };
    render() {
        return (
            <div style={classes.container}>
                <CSSTransition
                    in={this.successMessage ? true : false}
                    timeout={3000}
                    classNames={'my-alert'}
                    unmountOnExit
                >
                    <div className='alertMessage' >{this.successMessage}</div>

                </CSSTransition>
                <div className={'inputContainer'}>
                    <form style={{ padding: '40px' }}>
                        <h2>Trillium Admission Office - Internal</h2>
                        <h4 style={{ color: 'red', textAlign: 'center', marginBottom: '15px' }}>{this.alertMessage}</h4>
                        <h4 style={{ textAlign: 'center', marginBottom: '15px', color:'green' }}>{this.message}</h4>
                        <div style={{ margin: '0px auto', width: '90%' }}>
                            <input name={'email'} onChange={this.handleChange} style={classes.input} className={'inputLogin'} type={'text'} placeholder={'email'} />
                            <input name={'password'} onChange={this.handleChange} style={classes.input} className={'inputLogin'} type={'password'} placeholder={'password'} />
                            <button onClick={this.onSubmit} type="submit" className={'loginSubmit'}>login</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
};
decorate(Login, {
    alertMessage: observable,
    successMessage: observable,
    classes: observable
});
export default asEntity({ storeId: 'Login' })(observer(Login));
