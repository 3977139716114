import { decorate, observable } from 'mobx';
import React from 'react';
import { observer } from 'mobx-react';
import {classes} from '../Styles/Public Style';
import { asEntity } from 'Hoc';
import { from } from 'seamless-immutable';
class Child extends React.Component {
    info = {};
    editBool = false;
    id;
    date;
    attributesNames = ['First name', 'Middle name', 'Last name' , 'Gender', 'Home address', 'Home number', 'Birth day', 'Birth month', 'Birth year'];
    required = [];
    constructor(props) {
        super(props);
        this.state = {};
    }
    onEditClick = () => {
        this.editBool = !this.editBool;
        if (!this.editBool) this.onSubmit();
    }

    getInfoObject() {
        console.log(this.info);
        var keys = Object.keys(this.props.childInfo);
        let object = {};
        keys.map(key => {
            object[key] = this.props.childInfo[key];
        });
        let data = {};
        data['ChildFirstName'] = object.child_first_name;
        data['ChildMiddleName'] = object.child_middle_name;
        data['ChildLastName'] = object.child_last_name;
        data['ChildGender'] = object.child_gender;
        data['ChildHomeAddress'] = object.child_home_address;
        data['ChildHomeNumber'] = object.child_home_number;
        this.date = new Date(object.child_date_of_birth);
        console.log(this.date);
        data['Day'] = this.date.getDate();
        data['Month'] = this.date.getMonth() + 1;
        data['Year'] = this.date.getFullYear();
        console.log(data);
        return data;
    };
    componentDidMount = props => {
        this.id = this.props.id;
        console.log(this.id);
        this.info = this.getInfoObject();
        this.setState({
            ...this.info
        }, () => {
            console.log(this.state);
            this.renderInfo();
        });
        this.day = [];
        this.month = [];
        this.year = [];
        for (let i = 1; i <= 31; i++)this.day.push(i);
        for (let i = 1; i <= 12; i++)this.month.push(i);
        for (let i = 2012; i <= 2020; i++)this.year.push(i);

    };
    handleChange = ev => {
        const name = ev.target.name;
        let value = ev.target.value;
        this.setState({
            ...this.state,
            [name]: value
        });
    };
    entityDidCatch(message) {
        this.editBool = true;
        this.props.displayMessage('validationError', message);
    };
    entityDidPosted = (data) => {
        this.editBool = false;
        this.props.editSuccess('Child information');
        console.log(data);
    };
    onSubmit = ev => {
        console.log('qwsqwswq');
        let data = Object.assign({}, this.state);
        data['ChildDateOfBirth'] = this.state.Year + '-' + this.state.Month + '-' + this.state.Day;
        delete data.Day;
        delete data.Year;
        delete data.Month;
        const copy = Object.assign({}, this.state);
        let keys = Object.keys(copy);
        this.required = [];
        keys.map(info => {
            console.log(info);
            if (!copy[info])
                this.required.push(info);
        });
        console.log(this.required);
        if (this.required.length <= 0)
            this.props.entityStore.post({ data: data, id: this.id });
        else {
            this.editBool = true;
            let plural = this.required.length > 1 ? ' are' : ' is';
            this.props.errorValidators(this.required, plural + ' required when child is present');
        }
    }
    renderInfo = () => {
        var keys = Object.keys(this.state);
        this.arrayOfInfo = [];
        this.arrayOfInfo.push(<button onClick={this.onEditClick} className={'editBtn'}>{this.state && !this.editBool ? 'Edit' : 'Save'}</button>);
        keys.map((attribute, index) => {
            console.log(attribute, index, this.state[attribute]);
            this.arrayOfInfo.push(
                <div key={this.attributesNames[index]} className={'eachInfo'}>
                    <p className={'label'}>{this.attributesNames[index]}: </p>
                    {!this.editBool ?
                        <span className={'labelAnswer'}>  {this.state[attribute]}</span>
                        : (this.editBool && (attribute === 'Year' || attribute === 'Month' || attribute === 'Day')) ?
                            <select style={classes.select} value={this.state[attribute]} name={attribute} onChange={this.handleChange}>
                                {this[attribute.toLocaleLowerCase()].map((i) => {
                                    return <option>{i}</option>;
                                })}
                            </select>
                            :
                            <input name={attribute} value={this.state[attribute]} onChange={this.handleChange} className={'input'} type='text' />

                    }
                </div>
            )
        });
        return this.arrayOfInfo;
    }
    render() {

        return (
            <div className={'cardComponent'}>
                <div>
                    <p className={'header'}>Child information</p>
                    <div className={'cardContainer'} >
                        <div className={'eachInfo'}>
                            {this.state ? this.renderInfo() : null}
                        </div>

                    </div>
                </div>
            </div>
        )
    }
};
decorate(Child, {
    editBool: observable
});
export default asEntity({ storeId: 'Child' })(observer(Child));
