const DownloadIcon = require('Assets/svg/download.svg');
const headers = [
  'Child First Name',
  'Child Last Name',
  'Submitted From',
  'Branch',
  'Payment Status',
  'Submitted At',
  'Actions',
];
const dateOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
};

export { headers, DownloadIcon, dateOptions };
