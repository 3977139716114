import React from 'react';
import styles from './styles';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

var data = observable({});

const Branches = observer(props => {
  return (
    <>
      <span style={styles.branch}>{'Branch'}:</span>
      <span>
        <select onChange={props.changeBranch} style={styles.select}>
          <option value=''>All</option>
          {props.branches.map(item => {
            return <option value={item.name}>{item.name}</option>;
          })}
        </select>
      </span>
    </>
  );
});
export default Branches;
