import React from 'react';
import ResizeIcon from 'Assets/ResizeIcon.jpg';
import { Menu, Sidebar } from 'semantic-ui-react';
import { styles, wrapper } from './styles';
import { Link } from 'react-router-dom';

export default function SideBar(props) {
  return (
    <>
      <Sidebar as={Menu} animation='overlay' icon='labeled' inverted vertical visible width='wide' style={props.style}>
        <div style={styles.header1}>
          Dashboard
          <img style={styles.img} alt='Resize Icon' src={ResizeIcon} onClick={props.changeMenu} />
        </div>
        <div style={styles.pointer}>
          <div style={styles.header2}>NAVIGATION</div>
          <Link
            to={{
              pathname: `/dashboard/admissions`,
            }}
          >
            <div style={wrapper(props.expanded)}>
              <div style={styles.tab}>Admissions</div>
              <img width='20' height='20' src={require('Assets/svg/menu.svg')} alt='list' />
            </div>
          </Link>
        </div>
        <div style={styles.bottom}>Trillium Preschool</div>
        <br />
      </Sidebar>

      <Sidebar.Pusher>
        {/* <Segment basic> */}
        {props.children}
        {/* </Segment> */}
      </Sidebar.Pusher>
    </>
  );
}
