import { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';

import EntityActions from 'Redux/Actions/Entity';

class EntityComponent extends Component {
  get storeId() {
    const { storeId } = this.props;
    return storeId;
  }

  get store() {
    const { entityStore } = this.props;

    return get(entityStore, `byId.${this.storeId}`, null);
  }
  get entityStore() {
    return {
      ...this.store,
      get: this.get,
      post: this.post,
      put: this.put,
      delete: this.delete,
    };
  }

  register() {
    this.props.register(this.storeId);
  }

  get(data) {
    this.props.get(this.storeId, data);
  }

  delete(data) {
    this.props.delete(this.storeId, data);
  }

  post(data) {
    this.props.post(this.storeId, data);
  }

  reset() {
    this.props.reset(this.storeId);
  }

  resetProp(prop) {
    this.props.resetProp(this.storeId, prop);
  }

  resetResponseProps() {
    this.props.resetResponseProps(this.resetProp);
  }

  componentDidMount() {
    if (this.storeId && !this.store) {
      this.props.register(this.storeId);
    }

    // Set the ref
    this.props.entityRef(this);

    this.props.entityDidMount && this.props.entityDidMount();
  }

  componentDidUpdate() {
    const { received,deleted, posted, errors, responseFromGet, responseFromDelete, responseFromPost } = this.store;
    if (received && !errors) {
      this.props.onChange(responseFromGet);
      this.props.onEntityReceived(responseFromGet);
      this.props.resetProp(this.storeId, 'received');
    }

    if (posted && !errors) {
      this.props.onChange(responseFromPost);
      this.props.onEntityPosted(responseFromPost);
      this.props.resetProp(this.storeId, 'posted');
    }

    if (received && errors) {
      this.props.onChange(errors);
      this.props.onEntityReceivedError(errors);
      this.props.resetProp(this.storeId, 'received');
    }

    if (posted && errors) {
      this.props.onChange(errors);
      this.props.onEntityPostedError(errors);
      this.props.resetProp(this.storeId, 'posted');
    }

    if (deleted && !errors) {
      console.log(this.props, this.store);
      this.props.onChange(responseFromDelete);
      console.log(this.props,this.props.onEntityDelete);
      this.props.onEntityDelete(responseFromDelete);
      this.props.resetProp(this.storeId, 'deleted');
    }

    if (deleted && errors) {
      console.log(this.props, this.store);
      this.props.onChange(responseFromDelete);
      this.props.onEntityDeleteError(errors);
      this.props.resetProp(this.storeId, 'deleted');
    }
  }

  componentWillUnmount() {
    const { storeId } = this.props;

    if (storeId && this.store) {
      this.props.resetProp(storeId, 'loading');
    }
  }

  render() {
    const { storeId, render } = this.props;

    if (storeId && this.store) {
      return render(this.entityStore) || null;
    }

    return null;
  }
}

EntityComponent.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  storeId: PropTypes.string.isRequired,

  entityStore: PropTypes.object,

  entityRef: PropTypes.func,
  onEntityReceived: PropTypes.func,
  entityDidMount: PropTypes.func,
  onEntityDelete: PropTypes.func,
  onEntityPosted: PropTypes.func,
  onEntityReceivedError: PropTypes.func,
  onEntityPostedError: PropTypes.func,

  onChange: PropTypes.func,

  render: PropTypes.func,

  register: PropTypes.func,
  get: PropTypes.func,
  post: PropTypes.func,
  delete: PropTypes.func,

  reset: PropTypes.func,
  resetProp: PropTypes.func,
  resetResponseProps: PropTypes.func,
};

EntityComponent.defaultProps = {
  storeId: null,
  render() {},
  entityRef() {},
  onChange() {},
  onEntityReceived() {},
  entityDidMount() {},
  onEntityPosted() { },
  onEntityDelete() { },
  onEntityPostedError() {},
  onEntityReceivedError() {},
};

const mapStateToProps = store => ({
  entityStore: store.entity,
});
  
const mapDispatchToProps = dispatch => ({
  register: id => dispatch(EntityActions.register(id)),
  get: (id, data) => dispatch(EntityActions.get(id, data)),
  post: (id, data) => dispatch(EntityActions.post(id, data)),
  delete: (id, data) => dispatch(EntityActions.delete(id, data)),
  reset: id => dispatch(EntityActions.reset(id)),
  resetProp: (id, prop) => dispatch(EntityActions.resetProp(id, prop)),
  resetResponseProps: id => dispatch(EntityActions.resetResponseProps(id)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(EntityComponent);
