import { call, put, select } from 'redux-saga/effects';
import get from 'lodash/get';

import api from '../../Services/Api';

import AccountActions from '../Actions/Account';
import { StorageKeys } from '../../Configs/Storage';
// import StartAuth from "~/Navigation/Auth";

export default {
  *setToken({ token }) {
    yield localStorage.setItem(StorageKeys.token, token);
  },
  *checkStatus() {
    const token = yield localStorage.getItem(StorageKeys.token);
    if (token) {
      yield put(AccountActions.setToken(token));
    } else {
      yield put(AccountActions.getTokenFailed());
    }
  },

  *getProfile() {
    
  },

  *logout({ screen }) {
    yield localStorage.removeItem(StorageKeys.token);
    yield put(AccountActions.reset());
  }
};
