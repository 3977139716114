import React from 'react';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import { asEntity } from 'Hoc';
import { classes } from '../Styles/Public Style';
class Marital extends React.Component {
    info = {};
    editBool = false;
    id;
    required = [];
    constructor(props) {
        super(props);
        this.state = {};
    }
    onEditClick = () => {
        this.editBool = !this.editBool;
        if (!this.editBool) this.onSubmit();
    };
    entityDidCatch(message) {
        this.editBool = true;

        this.props.displayMessage('validationError', message);
    };
    entityDidPosted = (data) => {
        this.editBool = false;

        this.props.editSuccess('Matrial informations');
        console.log(data);
    };
    getInfoObject() {
        var keys = Object.keys(this.props.maritalInfo);
        let object = {};
        keys.map(key => {
            object[key] = this.props.maritalInfo[key];
        });
        let data = {};
        console.log(object);
        data['MaritalStatus'] = object.marital_status;
        if (object.marital_status == 'Divorced') {
            this.divorcied = true;
            data['DivorceLegalMatters'] = object.divorce_legal_matters;
        }

        return data;
    };
    componentDidMount = props => {
        this.id = this.props.id;
        this.info = this.getInfoObject();
        this.setState({
            ...this.info
        });
    };
    handleChange = (ev, required) => {
        const name = ev.target.name;
        let value = ev.target.value;
        if (name == 'MaritalStatus' && value == 'Divorced') {
            this.state['DivorceLegalMatters'] = this.state.DivorceLegalMatters;
            this.divorcied = true;
        }
        else if (name == 'MaritalStatus' && value != 'Divorced') {
            this.divorcied = false;
            this.state.DivorceLegalMatters = null;
        }
        this.setState({
            ...this.state,
            [name]: value
        },()=>{
            console.log(this.state);
        });

    };
    onSubmit = ev => {
        this.required = [];
        if (this.state.MaritalStatus && ((this.state.MaritalStatus != 'Divorced' && !this.state.DivorceLegalMatters) || (this.state.MaritalStatus == 'Divorced' && this.state.DivorceLegalMatters) ))
            this.props.entityStore.post({ data: this.state, id: this.id });
        else {
            this.editBool = true;
            this.props.errorValidators(['LegalDivorcedMatters'], ' is' + ' required');
        }
    }
    render() {
        return (
            <div className={'cardComponent'}>
                <div>
                    <p className={'header'}>Marital Status</p>
                    <div className={'cardContainer'} >
                        <button onClick={this.onEditClick} className={'editBtn'}>{!this.editBool ? 'Edit' : 'Save'}</button>
                        <div className={'eachInfo'}>
                            <p className={'label'}>Status:</p>
                            {!this.editBool ?
                                <span className={'labeAnswer'}> {this.state.MaritalStatus}</span>
                                :
                                <select value={this.state.MaritalStatus} name={'MaritalStatus'} onChange={(e) => this.handleChange(e, true)} style={classes.select}>
                                    <option>Married</option>
                                    <option>Divorced</option>
                                    <option>Widow</option>
                                </select>
                            }
                            {this.divorcied ?
                                <> <br /><p className={'label'}>Divorce legal matters:   </p></> : null
                            }
                            {this.divorcied ?
                                !this.editBool ? <span className={'labelAnswer'}>{this.state.DivorceLegalMatters}</span> :
                                    <input value={this.state.DivorceLegalMatters} onChange={(e) => this.handleChange(e, this.state.MaritalStatus == 'Divorced' ? true : false)} name={'DivorceLegalMatters'} className={'input'} type='text' />
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};
decorate(Marital, {
    editBool: observable,
    divorcied: observable
});
export default asEntity({ storeId: 'Marital' })(observer(Marital));
