const styles = {
  root: {
    flexGrow: 1,
  },
  header1: {
    color: 'white',
    backgroundColor: '#2d3e63',
    lineHeight: '60px',
    fontWeight: 700,
  },
  header2: {
    margin: 10,
    marginTop: 15,
    marginLeft: 20,
    display: 'block',
    textAlign: 'left',
    color: '#738bc0',
    fontWeight: 700,
  },
  img: {
    width: '28px',
    marginTop: '20px',
    float: 'right',
    marginRight: '20px',
    cursor: 'pointer',
  },
  tab: {
    color: 'white',
    fontWeight: 700,
  },
  bottom: {
    position: 'absolute',
    color: '#b2bfdc',
    height: '40px',
    paddingTop: '10px',
    width: '100%',
    backgroundColor: '#2d3e63',
    bottom: 0,
  },
  pointer: { cursor: 'pointer' },
};
const wrapper = expanded => {
  return {
    backgroundColor: '#0F5B82',
    display: 'flex',
    justifyContent: expanded ? 'space-around' : 'center',
    alignItems: 'center',
    padding: 10,
  };
};
export { styles, wrapper };
