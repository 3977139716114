import React from 'react';
import '../Styles/styles.css';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import { asEntity } from 'Hoc';
import { Entity } from 'Components';
import { classes } from '../Styles/Public Style';
class Sibling extends React.Component {
  info = {};
  editBool = false;
  id;
  date = new Date();

  requiredVal = ['SiblingFirstName', 'SiblingLastName', 'SiblingMiddleName', 'Day', 'Month', 'Year'];
  attributesNames = ['First name', 'Last name', 'Middle name', 'Birth day', 'Birth month', 'Birth year'];
  constructor(props) {
    super(props);
    this.state = {};
  }
  onEditClick = () => {
    this.editBool = !this.editBool;
    if (!this.editBool) this.onSubmit();
  };
  entityDidCatch(message) {
    this.editBool = true;
    this.props.displayMessage('validationError', message);
  }
  entityDidPosted = data => {
    this.editBool = false;
    this.props.editSuccess('Sibling information');
  };
  getInfoObject() {
    var keys = Object.keys(this.props.siblingsInfo);
    let object = {};
    keys.map(key => {
      object[key] = this.props.siblingsInfo[key];
    });
    let data = {};
    data['SiblingFirstName'] = object.first_name;
    data['SiblingMiddleName'] = object.middle_name;
    data['SiblingLastName'] = object.last_name;
    this.date = new Date(object.date_of_birth);
    if (this.props.new) {
      data['Day'] = null;
      data['Month'] = null;
      data['Year'] = null;
    } else {
      data['Day'] = this.date.getDate();
      data['Month'] = this.date.getMonth() + 1;
      data['Year'] = this.date.getFullYear();
    }
    console.log(data);
    return data;
  }
  componentDidMount = props => {
    this.id = this.props.id;
    this.info = this.getInfoObject();
    this.setState({
      ...this.info,
    });
    this.day = [];
    this.month = [];
    this.year = [];
    for (let i = 1; i <= 31; i++) this.day.push(i);
    for (let i = 1; i <= 12; i++) this.month.push(i);
    for (let i = 2012; i <= 2020; i++) this.year.push(i);
    if (this.props.new) {
      this.editBool = true;
    }
  };
  handleChange = ev => {
    const name = ev.target.name;
    let value = ev.target.value;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  onSubmit = ev => {
    let data = Object.assign({}, this.state);
    data['SiblingDateOfBirth'] = this.state.Year + '-' + this.state.Month + '-' + this.state.Day;
    delete data.Day;
    delete data.Year;
    delete data.Month;
    let keys = Object.keys(this.state);
    this.required = [];
    keys.map(info => {
      if (!this.state[info]) this.required.push(info);
    });
    if (this.required.length <= 0) {
      if (this.props.new) this.props.newSiblingData({ data: data });
      else this.props.entityStore.post({ data: data, id: this.props.id });
    } else {
      let plural = this.required.length > 1 ? ' are' : ' is';
      this.editBool = true;

      this.props.errorValidators(
        this.required,
        plural + ' required when sibling ' + parseInt(this.props.siblingNo) + ' is present'
      );
    }
  };
  callRemoveFunction = () => {
    if (!this.props.id) {
      this.props.removeSiblingUICard(true);
    } else {
      this.props.removeSiblingData({ id: this.props.id });
    }
  };
  renderInfo = () => {
    var keys = Object.keys(this.state);
    this.arrayOfInfo = [];
    this.arrayOfInfo.push(
      <>
        <button onClick={this.onEditClick} className={'editBtn'}>
          {this.state && !this.editBool ? 'Edit' : 'Save'}
        </button>
        <button style={classes.remove} onClick={this.callRemoveFunction} className={'editBtn'}>
          {'Remove'}
        </button>
      </>
    );
    keys.map((attribute, index) => {
      console.log(attribute, index, this.state[attribute]);
      this.arrayOfInfo.push(
        <div key={this.attributesNames[index]} className={'eachInfo'}>
          <p className={'label'}>{this.attributesNames[index]}: </p>
          {!this.editBool ? (
            <span className={'labelAnswer'}> {this.state[attribute]}</span>
          ) : this.editBool && (attribute === 'Year' || attribute === 'Month' || attribute === 'Day') ? (
            <select style={classes.select} value={this.state[attribute]} name={attribute} onChange={this.handleChange}>
              {this[attribute.toLocaleLowerCase()].map(i => {
                return <option>{i}</option>;
              })}
            </select>
          ) : (
            <input
              name={attribute}
              value={this.state[attribute]}
              onChange={this.handleChange}
              className={'input'}
              type='text'
            />
          )}
        </div>
      );
    });
    return this.arrayOfInfo;
  };
  render() {
    return (
      <>
        <div className={''}>
          <div>
            <p className={'header'}>Sibling {this.props.siblingNo}</p>
            <div className={'cardContainer'}>
              <div className={'eachInfo'}>{this.state ? this.renderInfo() : null}</div>
            </div>
          </div>
        </div>

        <Entity storeId={'RemoveSibling'} entityRef={ref => (this.removeSibling = ref)} onEntityPosted={result => {}} />
        <Entity
          storeId={'NewSibling'}
          entityRef={ref => (this.newSibling = ref)}
          onEntityPosted={result => {
            this.id = result.id;
            console.log(result);
          }}
        />
      </>
    );
  }
}
decorate(Sibling, {
  editBool: observable,
});
export default asEntity({ storeId: 'Sibling' })(observer(Sibling));
