export const errorValidators = (array, message) => {
    array.forEach((attribute, index) => {
        let word = '';
        for (let i = 0; i < attribute.length; i++) {
            if (attribute[i].toLowerCase() !== attribute[i] && i != 0)
                word += ' ' + attribute[i];
            else word += attribute[i];
        }
        array[index] = word;
    });
    return array + message;
}
