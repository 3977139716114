import React, { Component } from 'react';
import { asEntity } from 'Hoc';
import { observer } from 'mobx-react';
import { decorate, observable } from 'mobx';
import Actions from './Actions';
import Payment from './Payment';
import Branches from './Branches';
import { Entity } from 'Components';
import fileSaver from 'file-saver';
import { headers, DownloadIcon, dateOptions } from './data';
import Paginator from 'my-react-paginator';
import './styles.css';
import styles from './styles';

class ViewStudents extends Component {
  constructor() {
    super();
    this.fetchStudents = this.fetchStudents.bind(this);
    this.changeBranch = this.changeBranch.bind(this);
    this.changePage = this.changePage.bind(this);
    this.exportExcel = this.exportExcel.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
  }

  page = 1;
  totalPages = 1;
  branch = '';
  loading = false;
  message = '';
  branches = [];
  students = [];
  student = {};

  componentDidMount = () => {
    this.fetchStudents();
    this.viewBranches.get();
  };
  fetchStudents() {
    this.props.entityStore.get({ page: this.page, branch: this.branch });
  }
  entityDidReceived = ({ items, total_count, num_items_per_page }) => {
    this.totalPages = Math.ceil(total_count / num_items_per_page);
    this.students = items;
    console.log(items);
  };
  changePage(num) {
    this.page = num;
    this.fetchStudents();
  }
  changeBranch(e) {
    this.branch = e.target.value;
    this.fetchStudents();
  }
  exportExcel() {
    this.message = 'Exporting Excel...';
    this.loading = true;
    this.exportExcelFile.get();
  }
  exportPDF(student) {
    this.student = student;
    this.message = 'Exporting PDF...';
    this.loading = true;
    this.generatePDF.get({ id: student.id });
  }

  renderHeader() {
    return headers.map(head => (
      <>
        <th style={styles.first_row_cell}>
          {head === 'Branch' ? <Branches branches={this.branches} changeBranch={this.changeBranch} /> : head}
        </th>
      </>
    ));
  }

  render() {
    return (
      <div style={styles.root}>
        {this.loading && (
          <div className='overlay'>
            <div className='message'>{this.message}</div>
          </div>
        )}
        <div style={styles.button}>
          <span onClick={this.exportExcel} style={styles.pointer}>
            <img style={styles.img} width='28' height='28' alt='download' src={DownloadIcon} />
            <span>Export Excel</span>
          </span>
        </div>
        <div style={styles.wrapper}>
          <div style={styles.table_wrapper}>
            <table>
              <tr>{this.renderHeader()}</tr>
              {this.students.map(student => {
                return (
                  <tr>
                    <th style={styles.cell}>{student.childFirstName}</th>
                    <th style={styles.cell}>{student.childLastName}</th>
                    <th style={styles.cell}>{'On Site'}</th>
                    <th style={styles.cell}>{student.branch}</th>
                    <th style={styles.payment_cell}>
                      <Payment student={student} paid={student.paid} id={student.id} />
                    </th>
                    <th style={styles.cell}>{new Date(student.createdAt).toLocaleTimeString('en-GB', dateOptions)}</th>
                    <th style={styles.cell}>
                      <Actions student={student} exportPDF={this.exportPDF} />
                    </th>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
        <Entity
          storeId={'ViewBranches'}
          entityRef={ref => (this.viewBranches = ref)}
          onEntityReceived={data => {
            this.branches = data;
          }}
        />
        <Entity
          storeId={'ExportExcel'}
          entityRef={ref => (this.exportExcelFile = ref)}
          onEntityReceived={data => {
            if (data) fileSaver.saveAs(data, `Admissions_${new Date().toLocaleDateString('en-GB')}`);
            this.loading = false;
          }}
        />
        <Entity
          storeId={'GeneratePDF'}
          entityRef={ref => (this.generatePDF = ref)}
          onEntityReceived={data => {
            if (data)
              fileSaver.saveAs(
                data,
                `Admission_${this.student.childFirstName}_${this.student.childLastName}_${new Date().toLocaleDateString(
                  'en-GB'
                )}`
              );

            this.loading = false;
            this.student = {};
          }}
        />
        <Paginator
          containerStyle={styles.containerStyle}
          pageStyle={styles.pageStyle}
          activeColor='#347AB7'
          position='right'
          page={this.page}
          totalPages={this.totalPages}
          onPageChange={this.changePage}
        />
      </div>
    );
  }
}
decorate(ViewStudents, {
  students: observable,
  student: observable,
  page: observable,
  totalPages: observable,
  loading: observable,
  message: observable,
});

export default asEntity({ storeId: 'ViewStudents' })(observer(ViewStudents));
