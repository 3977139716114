import React, { Component } from 'react';
import Routes from './Routes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import WithStore from 'Hoc/WithStore';
import StartupActions from 'Redux/Actions/Startup';
import { observer } from 'mobx-react';
import { decorate, observable } from 'mobx';

class App extends Component {
  token = null;
  loading = true;

  componentDidMount = () => {
    this.props.startup();
  };
  componentDidUpdate = () => {
    this.token = get(this.props, 'account.token', null);
    this.loading = false;
  };

  render() {
    return this.loading ? null : <Routes token={this.token} />;
  }
}

const mapStateToProps = store => ({
  account: store.account,
});

const mapDispatchToProps = dispatch => ({
  startup: () => dispatch(StartupActions.startup()),
});

App.propTypes = {
  account: PropTypes.object,
  startup: PropTypes.func,
};

App.defaultProps = {
  account: {},
  startup() {},
};
decorate(App, {
  token: observable,
  loading: observable,
});

export default WithStore(connect(mapStateToProps, mapDispatchToProps)(observer(App)));
