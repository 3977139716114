import _ from "lodash";
class Generator {
    constructor(info){
        this.data = info;
    }
    childInfo(){
        let info = _.pick(this.data, [
            'child_first_name',
            'child_middle_name',
            'child_gender',
            'child_last_name',
            'child_date_of_birth',
            'child_home_address',
            'child_home_number',
        ]);
        return info;
    }
    dadInfo() {
        let info = _.pick(this.data, [
            'dad_first_name',
            'dad_last_name',
            'dad_middle_name',
            'dad_mobile_number',
            'dad_email',
            'dad_graduated_from',
            'dad_occupation',
            'dad_work_number'
        ]);
        return info;
    }
    momInfo() {
        let info = _.pick(this.data, [
            'mom_first_name',
            'mom_middle_name',
            'mom_last_name',
            'mom_mobile_number',
            'mom_email',
            'mom_graduated_from',
            'mom_occupation',
            'mom_work_number'
        ]);
        return info;
    }
    admissionInfo() {
        let info = _.pick(this.data, [
            'created_at',
            'updated_at',
            'paid',
            'submitted_from',
        ]);
        info.branch = this.data.branch.name;
        info.signature = this.data.signature;
        info.relation_to_child = this.data.relation_to_child;   

        return info;
    }
    maritalInfo() {
        let info = _.pick(this.data, [
            'marital_status',
            'divorce_legal_matters'
        ]);
        return info;
    }
    physicianInfo() {
        console.log(this.data);
        let info = _.pick(this.data, [
            'doctor_name',
            'has_allergies',
            'has_learning_disorders',
            'has_medical_conditions',
            'learning_disorders',
            'doctor_mobile_number',
            'medical_conditions',
            'allergies'
        ]);
        console.log(info);
        return info;
    }
    siblingInfo() {
        return this.data.sibling;
    }
    emergencyInfo() {
        return this.data.emergency_contact;
    }
    specialInfo() {
        return !this.data.special_information ? '' : this.data.special_information;
    }
    commentInfo() {
        return !this.data.comment ? '' : this.data.comment;
    }
}
export default Generator;