import React, { useState, createContext, useContext } from "react";

const AppContextData = {
 
};

// initializing context
export const AppContext = createContext(AppContextData);

// setting a helper function to use context
export const useAppContext = () => useContext(AppContext);

export function AppContextProvider({ children }) {
  const [appContextObj, setAppContext] = useState(AppContextData);
  return (
    <AppContext.Provider value={{ appContextObj, setAppContext }}>
      {children}
    </AppContext.Provider>
  );
}
