import React from 'react';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import { asEntity } from 'Hoc';
class SpecialInfo extends React.Component {
    editBool = false;
    constructor(){
        super();
        this.state = {};
    }
    componentDidMount(props){
        const data = this.getSpecialInfo();
        console.log(data);
        this.setState({
            specialInfo: data 
        },() =>{
            console.log(this.state);
        });
    }
    onEditClick = () => {
        this.editBool = !this.editBool;
        if (!this.editBool) this.onSubmit();
    }
    getSpecialInfo = () =>{
        var keys = Object.keys(this.props.specialInfo);
        this.specialInformation = this.props.specialInfo;
        console.log(this.specialInformation);
        return this.props.specialInfo;
    };
    handleChange = ev => {
        this.setState({
            ...this.state,
            [ev.target.name]: ev.target.value
        });
    };
    onSubmit = () => {
        console.log(this.state);
        const data = {SpecialInformation: this.state.specialInfo};
        this.props.entityStore.post({ data, id: this.props.id });
    };
    entityDidCatch(message) {
        this.editBool = true;

        this.props.displayMessage('validationError', message);
    };
    entityDidPosted = data => {
        this.editBool = false;
        const type = 'Special information';
        this.props.editSuccess(type);
    };
    render() {
        return (
            <div className={'cardComponent'}>
                <div>
                    <p className={'header'}>Special information</p>
                    <div className={'cardContainer'} style={{ minHeight: '60px' }}>
                        <button onClick={this.onEditClick} className={'editBtn'}>{!this.editBool ? 'Edit' : 'Save'}</button>
                        {this.editBool ?
                            <>
                                <textarea style={{height:'250px'}} value={this.state.specialInfo} name={'specialInfo'} onChange={this.handleChange} style={{ width: '65%' }} />
                            </>
                            : <span className={'labelAnswer'}>{!this.state.specialInfo ? 'Not available' : this.state.specialInfo}</span>
                        }
                    </div>
                </div>
            </div>
        )
    }
};
decorate(SpecialInfo,{
    editBool: observable
})
export default asEntity({ storeId: 'SpecialInfo' })(observer(SpecialInfo));
