import React from 'react';
import Comment from './Comment';
import SpecialInfo from './Special Info';
import Siblings from './Siblings information';
import Marital from './Martial State';
import Emeregency from './Emergency contacts';
import { asEntity } from 'Hoc';
import Physician from './Physician information';
import { decorate, observable } from 'mobx';
import _ from 'lodash';
import { observer } from 'mobx-react';
import AdmissionInfo from './Admission Info';
import { Entity } from 'Components';
import ChildInfo from './Child Info';
import DadInfo from './Dad Info';
import Mom from './Mom Info';
import ObjectGenerator from './Helpers/Object Generator';
import { errorValidators } from './Helpers/Error Validators';
import { classes } from './Styles/Public Style';
import fileSaver from 'file-saver';
import { Transition } from 'react-transition-group';
import { CSSTransition } from 'react-transition-group';
class ViewAdmissions extends React.Component {
  successMessage = null;
  validationError = null;
  newSiblingObject = [];
  loading = false;
  message = '';
  componentDidMount = () => {
    const path = window.location.pathname;
    this.id = path.substr(path.lastIndexOf('/') + 1);
    this.props.entityStore.get(this.id);
  };
  entityDidReceived = data => {
    if (data) {
      this.generateAdmissionObjects(data);
    } else {
      console.log(data);
    }
  };
  generateAdmissionObjects = data => {
    let objectGenerator = new ObjectGenerator(data);

    this.childInfo = objectGenerator.childInfo();

    this.dadInfo = objectGenerator.dadInfo();

    this.momInfo = objectGenerator.momInfo();

    this.admissionInfo = objectGenerator.admissionInfo();

    this.physicianInformation = objectGenerator.physicianInfo();

    this.maritalInfo = objectGenerator.maritalInfo();

    this.siblings = objectGenerator.siblingInfo();

    this.emeregencyContact = objectGenerator.emergencyInfo();

    this.specialInfo = objectGenerator.specialInfo();

    this.comment = objectGenerator.commentInfo();
  };
  entityDidCatch = message => {
    console.log(message);
    this.displayMessage('validationError', message);
  };
  getInfoObjectSiblings() {
    if (this.siblings) {
      var keys = Object.keys(this.siblings);
      var data = [];
      keys.map(key => {
        let temp = {};
        temp.first_name = this.siblings[key].first_name;
        temp.last_name = this.siblings[key].last_name;
        temp.middle_name = this.siblings[key].middle_name;
        temp.date_of_birth = this.siblings[key].date_of_birth;
        temp.id = this.siblings[key].id;
        data.push(temp);
      });
      return data;
    }
  }

  //Screen
  editSuccess = type => {
    const message = type + ' Saved successfully';
    this.displayMessage('successMessage', message);
  };
  errorValidators = (array, message) => {
    this.displayMessage('validationError', errorValidators(array, message));
  };
  displayMessage = (messageType, value) => {
    this[messageType] = value;
    console.log(this.successMessage);
    console.log(this[messageType]);
    setTimeout(() => {
      this[messageType] = null;
    }, 5000);
  };
  //End of Screen

  //New Sibling
  newSiblingData = data => {
    data.id = this.id;
    this.newSiblingObject = data.data;
    this.newSibling.post(data);
  };
  newSiblingsCall = () => {
    const siblings = this.getInfoObjectSiblings();
    let bool = true;
    if (siblings.length) {
      if (siblings[siblings.length - 1].id) {
        bool = true;
      } else {
        bool = false;
        this.displayMessage('validationError', 'You should save previous sibling first');
      }
    }
    if (bool) {
      siblings.push({
        first_name: '',
        middle_name: '',
        last_name: '',
        date_of_birth: '',
        id: '',
      });
    }
    this.siblings = Object.assign([], siblings);
    console.log(this.siblings);
  };
  //End

  //Delete
  removeSiblingData = data => {
    this.deletedID = data.id;
    this.removeSibling.delete(data);
  };
  removeSiblingUICard = isNew => {
    if (isNew) {
      console.log(this.siblings.length);
      this.siblings.splice(this.siblings.length - 1, 1);
      console.log(this.siblings.length);
    } else {
      var keys = Object.keys(this.siblings);
      keys.map(key => {
        console.log(key, this.siblings[key]);
        if (this.siblings[key] && parseInt(this.siblings[key].id) === this.deletedID) this.siblings.splice(key, 1);
      });
    }
  };
  exportPDF = () => {
    this.message = 'Exporting PDF...';
    this.loading = true;
    this.generatePDF.get({ id: this.id });
  };
  //End Of Deletion
  render() {
    return (
      <>
        {this.loading && (
          <div className='overlay'>
          
            <div className='message' >{this.message}</div>
          </div>
        )}
        <button style={classes.exportButton} className={'editBtn'} onClick={this.exportPDF}>
          Export PDF
        </button>

            <CSSTransition
              in={this.successMessage?true:false}
              timeout={3000}
            classNames={'my-alert'}
              unmountOnExit
            >
              <div className='alertMessage' >{this.successMessage}</div>

          </CSSTransition>

          <CSSTransition
            in={this.validationError ? true : false}
            timeout={3000}
            classNames={'my-alert'}
          >
            <div style={{ backgroundColor: '#d9534f' }} className='alertMessage'  >{this.validationError}</div>

          </CSSTransition>
        <div>

        </div>
        <div style={{ backgroundColor: '#f3f3f3', marginTop: '0px' }}>
          <div style={{ margin: 10 }}>
            {this.comment != null ? (
              <Comment
                displayMessage={this.displayMessage}
                comment={this.comment}
                id={this.id}
                editSuccess={this.editSuccess}
              />
            ) : null}
            {this.childInfo ? (
              <ChildInfo
                displayMessage={this.displayMessage}
                errorValidators={this.errorValidators}
                getFormatedDate={this.getFormatedDate}
                id={this.id}
                editSuccess={this.editSuccess}
                childInfo={this.childInfo}
              />
            ) : null}
            {this.momInfo ? (
              <Mom
                displayMessage={this.displayMessage}
                errorValidators={this.errorValidators}
                id={this.id}
                editSuccess={this.editSuccess}
                momInfo={this.momInfo}
              />
            ) : null}
            {this.dadInfo ? (
              <DadInfo
                displayMessage={this.displayMessage}
                errorValidators={this.errorValidators}
                id={this.id}
                editSuccess={this.editSuccess}
                dadInfo={this.dadInfo}
              />
            ) : null}
            {this.maritalInfo ? (
              <Marital
                displayMessage={this.displayMessage}
                errorValidators={this.errorValidators}
                id={this.id}
                editSuccess={this.editSuccess}
                maritalInfo={this.maritalInfo}
              />
            ) : null}
            <p className={'header'}>Sibling information</p>
            <div className={'cardContainer'}>
              <div>
                {this.siblings
                  ? Object.keys(this.siblings).map((key, index) => {
                      let info = _.pick(this.siblings[key], [
                        'date_of_birth',
                        'first_name',
                        'id',
                        'middle_name',
                        'last_name',
                      ]);
                      return (
                        <Siblings
                          removeSiblingUICard={this.removeSiblingUICard}
                          displayMessage={this.displayMessage}
                          key={info.id}
                          id={info.id}
                          newSiblingData={this.newSiblingData}
                          new={!info.id}
                          removeSiblingData={this.removeSiblingData}
                          errorValidators={this.errorValidators}
                          getFormatedDate={this.getFormatedDate}
                          siblingNo={index + 1}
                          editSuccess={this.editSuccess}
                          siblingsInfo={info}
                        />
                      );
                    })
                  : null}
              </div>
              <button onClick={this.newSiblingsCall} style={classes.addButton} className={'editBtn'}>
                Add sibling
              </button>
              <br />
              <br />
            </div>
            <p className={'header'}>Emergency information</p>

            <div className={'cardContainer'}>
              <div>
                {this.emeregencyContact
                  ? Object.keys(this.emeregencyContact).map((key, index) => {
                      let info = _.pick(this.emeregencyContact[key], ['mobile_number', 'name', 'relation', 'id']);
                      return (
                        <Emeregency
                          displayMessage={this.displayMessage}
                          key={info.id}
                          errorValidators={this.errorValidators}
                          getFormatedDate={this.getFormatedDate}
                          id={info.id}
                          emergencyNo={index + 1}
                          editSuccess={this.editSuccess}
                          emergencyInfo={info}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
            {this.physicianInformation ? (
              <Physician
                displayMessage={this.displayMessage}
                errorValidators={this.errorValidators}
                id={this.id}
                editSuccess={this.editSuccess}
                physicianInfo={this.physicianInformation}
              />
            ) : null}
            {this.specialInfo != null ? (
              <SpecialInfo
                displayMessage={this.displayMessage}
                specialInfo={this.specialInfo}
                id={this.id}
                editSuccess={this.editSuccess}
              />
            ) : null}
            {this.admissionInfo ? (
              <AdmissionInfo
                displayMessage={this.displayMessage}
                getFormatedDate={this.getFormatedDate}
                admissionInfo={this.admissionInfo}
                id={this.id}
                editSuccess={this.editSuccess}
              />
            ) : null}
          </div>
          <Entity
            storeId={'GeneratePDF'}
            entityRef={ref => (this.generatePDF = ref)}
            onEntityReceived={data => {
              fileSaver.saveAs(
                data,
                `Admission_${this.childInfo.child_first_name}_${
                  this.childInfo.child_last_name
                }_${new Date().toLocaleDateString('en-GB')}`
              );
              this.loading = false;
            }}
          />
        </div>
        <br />
        <Entity
          storeId={'NewSibling'}
          onEntityPostedError={message => {
            console.log(message);
            this.displayMessage('validationError', message);
          }}
          entityRef={ref => (this.newSibling = ref)}
          onEntityPosted={result => {
            console.log(this.newSiblingObject);
            this.siblings[this.siblings.length - 1] = {
              first_name: this.newSiblingObject.SiblingFirstName,
              last_name: this.newSiblingObject.SiblingLastName,
              middle_name: this.newSiblingObject.SiblingMiddleName,
              date_of_birth: this.newSiblingObject.SiblingDateOfBirth,
              id: result.sibling_id,
            };
            console.log(result);
            this.displayMessage('successMessage', result.message);
            console.log(this.siblings);
          }}
        />
        <Entity
          storeId={'RemoveSibling'}
          entityRef={ref => (this.removeSibling = ref)}
          onEntityDeleteError={message => {
            console.log(message);
            this.displayMessage('validationError', message);
          }}
          onEntityDelete={result => {
            console.log(result.message);
            this.removeSiblingUICard();
            this.displayMessage('successMessage', result.message);
          }}
        />
        <br />
      </>
    );
  }
}
decorate(ViewAdmissions, {
  successMessage: observable,
  validationError: observable,
  physicianInformation: observable,
  dadInfo: observable,
  comment: observable,
  id: observable,
  maritalStatus: observable,
  momInfo: observable,
  newEmergency: observable,
  siblings: observable,
  loading: observable,
  message: observable,
});
export default asEntity({ storeId: 'ViewAdmissions' })(observer(ViewAdmissions));
