import React from 'react';
import styles from './styles';
import { observer } from 'mobx-react';
import { Entity } from 'Components';
import './styles.css';

const Payment = observer(props => {
  var [checked, setChecked] = React.useState(props.paid);
  const editPayment = React.useRef(null);
  function toggleCheck() {
    setChecked(!checked);
    editPayment.current.post({ id: props.id });
  }
  function setRef(ref) {
    editPayment.current = ref;
  }
  React.useEffect(() => {
    setChecked(props.paid);
  }, [props.id]);
  return (
    <>
      Not Paid
      <span style={styles.checkbox}>
        <label class='switch' onChange={toggleCheck}>
          <input type='checkbox' checked={checked} />
          <span class='slider round'></span>
        </label>
      </span>
      Paid
      <Entity storeId={'EditPayment'} entityRef={setRef} onEntityPosted={result => {}} />
    </>
  );
});
export default Payment;
