export const classes = {
    select: {
        marginLeft: '5px',
    },
    remove: {
        marginRight: '10px',
        width: '70px'
    },
    eachRowMargin: {
        display: 'block',
        marginBottom: '10px'
    },
    exportButton: {
        width: '100px', float: 'right', marginRight: '70px', fontWeight: 'bold'
    },
    addButton: {
        float: 'left',
        width: '120px'
    },
    translate: {
        // marginTop:'200px',
        transform: 'scale(1)'
    }
};