import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './App.css';
import { AppContextProvider } from './Services/Providers';
import { SplashScreenProvider } from './Services/SplashScreen';

function Main() {
  return (
    <AppContextProvider>
      <SplashScreenProvider>
        <Router>
          <App />
        </Router>
      </SplashScreenProvider>
    </AppContextProvider>
  );
}

export default Main;
