const styles = {
  actions: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' },
  margin: { margin: 3 },
  pdf_button: {
    width: 50,
    height: 25,
    border: '1px solid gray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  view_link: {
    width: 80,
    height: 25,
    border: '1px solid black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
  },
};

export default styles;
