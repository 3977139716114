import React from 'react';
import Home from '../Screens/Home';
import View from 'Screens/Admissions/View';

import EditAdmission from '../Screens/Admin Console/Edit Admission';
const EmptyPage = () => (
  <dev style={{ position: 'absolute', top: '50px', left: '44%' }}>
    <h4 style={{}}> 404 not found! </h4>
  </dev>
);

export default [
  // { path: '/dashboard', component: Home, exact: true },
  { path: '/dashboard/admissions', component: View, exact: true },
  { path: '/admission', component: Home, exact: true },
  { path: '/Edit_Admission/:id', component: EditAdmission, exact: true },
];
