import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoginAdmin from 'Screens/Admin Console/Auth/Login';
import RouteList from './RouteList';
import Layout from '../Screens/Layout';
import { LayoutSplashScreen } from '../Services/SplashScreen';
import NotFound from './NotFound';

export default function index(props) {
  const isAuthorized = props.token;

  return (
    <Switch>
      {!isAuthorized ? <Route path='/login' exact component={LoginAdmin} /> : <Redirect from='/login' to='/' />}
      <Route path='/error' exact component={NotFound} />
      {!isAuthorized ? (
        <Redirect to='/login' />
      ) : (
        <Layout>
          <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
              {
                /* Redirect from root URL to /dashboard. */
                <Redirect exact from='/' to='/dashboard/admissions' />
              }
              {RouteList.map((RouteObj, index) => (
                <Route key={`app-route-${index}`} {...RouteObj} />
              ))}
              <Redirect to='/error' />
            </Switch>
          </Suspense>
        </Layout>
      )}
    </Switch>
  );
}
