import React, { useState } from 'react';
import { asEntity } from 'Hoc';
class Admission extends React.Component {
    date;
    attributesNames = ['Created at', 'Updated at', 'Paid', 'Sumitted from', 'Branch', 'Signature', 'Relation To Child'];
    monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    constructor(props) {
        super(props);
        this.state = {};
    }
    getInfoObject() {
        console.log(this.info);
        var keys = Object.keys(this.props.admissionInfo);
        let object = {};

        console.log(object);
        
        
        console.log(object);
        keys.map(key => {
            if (key=='created_at'){
                this.date = new Date(this.props.admissionInfo[key]);
                let hourStatus = this.date.getHours() >= 12 ? 'pm' : 'am';
                object[key] = this.date.getDate() + 'th ' + this.monthNames[this.date.getMonth()] + ' ' + this.date.getFullYear() + ', ' + this.date.getHours() + ':' + this.date.getMinutes() + hourStatus;
            }else if (key=='updated_at'){
                let hourStatus = this.date.getHours() >= 12 ? 'pm' : 'am';
                this.date = new Date(this.props.admissionInfo[key]);
                object[key] = this.date.getDate() + 'th ' + this.monthNames[this.date.getMonth()] + ' ' + this.date.getFullYear() + ', ' + this.date.getHours() + ':' + this.date.getMinutes() + hourStatus;
            }
            else object[key] = this.props.admissionInfo[key];
        });
        return object;
    };
    componentDidMount = props => {
        this.id = this.props.id;
        console.log(this.id);
        this.info = this.getInfoObject();
        this.setState({
            ...this.info
        }, () => {
            console.log(this.state);
            this.renderInfo();
        });
    };
    renderInfo = () => {
        var keys = Object.keys(this.state);
        this.arrayOfInfo = [];
        keys.map((attribute, index) => {
            console.log(attribute, index, this.state[attribute]);
            this.arrayOfInfo.push(
                <div className={'eachInfo'}>
                    <p className={'label'}>{this.attributesNames[index]}: </p>
                    <span className={'labelAnswer'}>  {attribute === 'paid' ? (this.state[attribute] ? 'Yes' : 'No') : this.state[attribute]} </span>
                </div>
            )
        });
        return this.arrayOfInfo;
    }
    render() {

        return (
            <div className={'cardComponent'}>
                <div>
                    <p className={'header'}>Admission Info</p>
                    <div className={'cardContainer'} >
                        <div className={'eachInfo'}>
                            {this.state ? this.renderInfo() : null}
                        </div>

                    </div>
                </div>
            </div>
        )
    }
};
export default asEntity({ storeId: 'Admission' })(Admission);
