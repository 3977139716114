import { decorate, observable } from 'mobx';
import React from 'react';
import { observer } from 'mobx-react';
import { asEntity } from 'Hoc';
import {classes} from '../Styles/Public Style';
class Physician extends React.Component {
    info = {};
    editBool = false;
    id;
    required = []
    constructor(props) {
        super(props);
        this.state = {};
    }
    onEditClick = () => {
        this.editBool = !this.editBool;
        if (!this.editBool) this.onSubmit();
    };
    entityDidCatch(message) {
        this.editBool = true;

        this.props.displayMessage('validationError', message);
    };
    entityDidPosted = (data) => {
        this.editBool = false;

        this.props.editSuccess('Physician information');
        console.log(data);
    };
    getInfoObject() {
        console.log(this.info);
        console.log(this.props.physicianInfo);
        var keys = Object.keys(this.props.physicianInfo);
        let object = {};
        keys.map(key => {
            object[key] = this.props.physicianInfo[key];
        });
        let data = {};
        data['DoctorName'] = object.doctor_name;
        data['DoctorMobileNumber'] = object.doctor_mobile_number;
        data['HasMedicalConditions'] = object.has_medical_conditions;
        data['MedicalConditions'] = object.medical_conditions;
        data['HasAllergies'] = object.has_allergies;
        data['Allergies'] = object.allergies;
        data['HasLearningDisorders'] = object.has_learning_disorders;
        data['LearningDisorders'] = object.learning_disorders;
        console.log(data);
        return data;
    };
    componentDidMount = props => {
        this.id = this.props.id;
        console.log(this.id);
        this.info = this.getInfoObject();
        this.setState({
            ...this.info
        }, () => {
            console.log(this.state);
        });
    };
    handleChange = (ev, required) => {
        const name = ev.target.name;
        let value = ev.target.value;

        console.log(required, value, name);
        console.log(this.required);
        if (name[0] == 'H' && value == 'yes') value = 1;
        else if (name[0] == 'H' && value == 'no') {
            value = 0;
            this.state[name.substring(3, name.length)] = null;
            this.required.splice(name.substring(3, name.length), 1);
        }
        console.log(this.required);

        if (required && value === 1 && name[0] == 'H') {
            this.required.push(name.substring(3, name.length));
            console.log(this.required);
        }
        console.log(required && (value === undefined || value === null || value === ''), name, value);
        if (required && (value === undefined || value === null || value === '')) {
            this.required.push(name);
        }
        if (required && value && this.required.indexOf(name) !== -1) {
            this.required.splice(this.required.indexOf(name), 1);
        }
        console.log(this.required);
        this.setState({
            ...this.state,
            [name]: value
        }, () => {
            console.log(this.state);
        });
    };
    onSubmit = ev => {
        var keys = Object.keys(this.state);
        console.log(this.required);
        keys.map(key => {
            if (!this.state[key] === 1 && key[0] === 'H' && !this.state[key.substring(3, key.length)]) {
                this.required.push(key.substring(3, key.length));
            }
            if (this.state[key] === undefined) this.state[key] = null;
            else if (this.state[key] === false) this.state[key] = 0;
            else if (this.state[key] === true) this.state[key] = 1;
        });
        console.log(this.state);
        let array = [];
        this.required.map((attr) => {
            if (array.indexOf(attr) == -1) array.push(attr);
        });
        console.log(array);
        if (array.length <= 0)
            this.props.entityStore.post({ data: this.state, id: this.id });
        else {
            let plural = array.length > 1 ? ' are' : ' is';
            this.props.errorValidators(array, plural + ' required');
            this.editBool = true;
        }
    }
    render() {

        return (
            <div className={'cardComponent'}>
                <div>
                    <p className={'header'}>Physician information</p>
                    <div className={'cardContainer'} >
                        <button onClick={this.onEditClick} className={'editBtn'}>{this.state && !this.editBool ? 'Edit' : 'Save'}</button>
                        <div className={'eachInfo'}>
                            <div className={'eachInfo'}>
                                <p className={'label'}>Doctor name: </p>
                                {this.state && !this.editBool ?
                                    <span className={'labelAnswer'}>  {this.state.DoctorName}</span>
                                    :
                                    <input name={'DoctorName'} value={this.state.DoctorName} onChange={(e, required) => this.handleChange(e, true)} className={'input'} type='text' />
                                }
                            </div>
                            <div className={'eachInfo'}>
                                <p className={'label'}>Mobile number: </p>
                                {this.state && !this.editBool ?
                                    <span className={'labelAnswer'}>  {this.state.DoctorMobileNumber ? this.state.DoctorMobileNumber : 'Not available'}</span>
                                    :
                                    <input name={'DoctorMobileNumber'} value={this.state.DoctorMobileNumber} onChange={(e) => this.handleChange(e, false)} className={'input'} type='text' />
                                }
                            </div>
                            <div className={'eachInfo'}>
                                <p className={'label'}>Has Allergies: </p>
                                {this.state && !this.editBool ?
                                    <span className={'labelAnswer'}>  {this.state.HasAllergies ? 'Yes' : 'No'}</span>
                                    :
                                    <select style={classes.select} value={this.state.HasAllergies ? 'yes' : 'no'} name={'HasAllergies'} onChange={(e) => this.handleChange(e, true)}>
                                        <option>yes</option>
                                        <option>no</option>
                                    </select>
                                }
                            </div>
                            <div className={'eachInfo'}>
                                <p className={'label'}>Has learning disorders: </p>
                                {this.state && !this.editBool ?
                                    <span className={'labelAnswer'}>  {this.state.HasLearningDisorders ? 'Yes' : 'No'}</span>
                                    :
                                    <select style={classes.select} value={this.state.HasLearningDisorders ? 'yes' : 'no'} name={'HasLearningDisorders'} onChange={(e) => this.handleChange(e, true)}>
                                        <option>yes</option>
                                        <option>no</option>
                                    </select>
                                }
                            </div>
                            <div className={'eachInfo'}>
                                <p className={'label'}>Has medical conditions: </p>
                                {this.state && !this.editBool ?
                                    <span className={'labelAnswer'}>  {this.state.HasMedicalConditions ? 'Yes' : 'No'}</span>
                                    :
                                    <select style={classes.select} value={this.state.HasMedicalConditions ? 'yes' : 'no'} name={'HasMedicalConditions'} onChange={(e) => this.handleChange(e, true)}>
                                        <option>yes</option>
                                        <option>no</option>
                                    </select>
                                }
                            </div>
                            {this.state.HasAllergies ?
                                <div className={'eachInfo'}>
                                    <p className={'label'}>Allergies: </p>
                                    {this.state && !this.editBool ?

                                        <span className={'labelAnswer'}>  {this.state.Allergies}</span>
                                        :
                                        <input name={'Allergies'} value={this.state.Allergies} onChange={(e) => this.handleChange(e, this.state.HasAllergies ? true : false)} className={'input'} type='text' />
                                    }
                                </div> : null}
                            {this.state.HasLearningDisorders ?
                                <div className={'eachInfo'}>
                                    <p className={'label'}>Learning disorders: </p>
                                    {this.state && !this.editBool ?

                                        <span className={'labelAnswer'}>  {this.state.LearningDisorders}</span>
                                        :
                                        <input name={'LearningDisorders'} value={this.state.LearningDisorders} onChange={(e) => this.handleChange(e, this.state.HasLearningDisorders ? true : false)} className={'input'} type='text' />

                                    }
                                </div> : null}
                            {this.state.HasMedicalConditions ?
                                <div className={'eachInfo'}>
                                    <p className={'label'}>Medical conditions: </p>
                                    {this.state && !this.editBool ?

                                        <span className={'labelAnswer'}>  {this.state.MedicalConditions}</span>
                                        :
                                        <input name={'MedicalConditions'} value={this.state.MedicalConditions} onChange={(e) => this.handleChange(e, this.state.HasMedicalConditions ? true : false)} className={'input'} type='text' />
                                    }
                                </div> : null}

                        </div>

                    </div>
                </div>
            </div>
        )
    }
};
decorate(Physician, {
    editBool: observable
});
export default asEntity({ storeId: 'Physician' })(observer(Physician));
