import backgroundImages from './data';
export const classes = {
    container: {
        backgroundImage: `url(${backgroundImages[Math.floor(Math.random() * 7)]})`,
        height: '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no - repeat',
        backgroundSize: 'cover',
    },
    input: {
        height: '50px', width: '100%', paddingLeft: '20px', display: 'block', marginBottom: '15px'
    },
    translate: {

    }
};