import React from 'react';
import './styles.css';
import '../Styles/styles.css';
import { asEntity } from 'Hoc';
class Comment extends React.Component {

    handleChange = ev =>{
        this.setState({
            ...this.state,
            [ev.target.name]:ev.target.value
        });
    };
    constructor(){
        super();
        this.state = {};
    }
    componentDidMount = () =>{
        console.log(this.props);
        this.setState({
            comment: this.props.comment
        });
    };

    onSubmit = () =>{
        console.log(this.state);
        const data = { Comment: this.state.comment};
        this.props.entityStore.post({ data, id: this.props.id});
    };
    entityDidCatch(message) {
        this.props.displayMessage('validationError', message);
    };
    entityDidPosted = data =>{
        const type = 'Comment';
        this.props.editSuccess(type);
    };
    render() {
        return (
            <div className={'cardComponent'}>
                <div>
                    <p className={'header'}>Comments</p>
                    <div className={'cardContainer'} style={{ height: '250px' }}>
                        <textarea value={this.state.comment} name={'comment'} onChange={this.handleChange} style={{ width: '100%'}} placeholder='Comment Box' />
                        <button onClick={this.onSubmit} style={{ marginTop: '10px' }} className={'editBtn'}>Save</button>
                    </div>
                </div>
            </div>
        )
    }
};
export default asEntity({ storeId: 'Comment' })(Comment);
