const styles = {
  root: { flexGrow: 1 },
  first_row_cell: {
    width: '150px',
    height: '38px',
    padding: 8,
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'left',
    color: 'black',
    borderBottom: '1px solid #dddddd',
  },

  cell: {
    width: '190px',
    height: '45px',
    padding: 8,
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'left',
    color: 'lightblack',
    borderBottom: '1px solid #dddddd',
  },
  payment_cell: {
    width: '210px',
    height: '45px',
    padding: 8,
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'left',
    color: 'lightblack',
    borderBottom: '1px solid #dddddd',
  },
  table_wrapper: { overflow: 'auto', height: window.innerHeight * 0.69, margin: 7 },
  wrapper: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
  button: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 30,
    backgroundColor: '#f3f3f3',
    fontSize: 14,
    fontWeight: 700,
    color: '#347AB7',
  },
  pointer: { cursor: 'pointer' },
  img: { paddingTop: 10 },
  containerStyle: {
    right: window.innerHeight < 650 ? '5px' : '30px',
    bottom: window.innerHeight < 650 ? '2px' : '50px',
    zIndex: 15000,
  },
  pageStyle: {
    borderRadius: 0,
    margin: 0,
    border: '1px solid lightgray',
    fontWeight: 700,
    width: 33,
    height: 33,
  },
};

export default styles;
