const Seconds = n => n * 1000;

const baseURL = !!process.env.PUBLIC_URL ? process.env.PUBLIC_URL : 'https://dev.api.admissions.garonzlab.com/';

const apiUrl = !!process.env.REACT_APP_API_URI ? process.env.REACT_APP_API_URI : `${baseURL}api`;

export default {
  baseURL,
  apiUrl,
  timeout: Seconds(100),
  clientToken: 'SECRET_TOKEN',
  version: '1.0.0',
};
