import React from 'react';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import { asEntity } from 'Hoc';
import '../Styles/styles.css';
class Emergency extends React.Component {
    info = {};
    editBool = false;
    id;
    required = [];
    requiredVal = ['EmergencyName', 'EmergencyMobileNumber', 'EmergencyRelation'];
    attributesNames = ['name', 'relation', 'mobile number'];
    constructor(props) {
        super(props);
        this.state = {};
    }
    onEditClick = () => {
        this.editBool = !this.editBool;
        if (!this.editBool) this.onSubmit();
    }
    entityDidCatch(message) {
        this.editBool = true;

        this.props.displayMessage('validationError', message);
    };
    entityDidPosted = (data) => {
        this.editBool = false;

        this.props.editSuccess('Emergency information');
        console.log(data);
    };
    getInfoObject() {
        var keys = Object.keys(this.props.emergencyInfo);
        let object = {};
        keys.map(key => {
            object[key] = this.props.emergencyInfo[key];
        });
        let data = {};
        data['EmergencyName'] = object.name;
        data['EmergencyRelation'] = object.relation;
        data['EmergencyMobileNumber'] = object.mobile_number;
        console.log(data);
        return data;
    };
    componentDidMount = props => {
        this.id = this.props.id;
        console.log(this.id);
        this.info = this.getInfoObject();
        this.setState({
            ...this.info
        }, () => {
            console.log(this.state);
        });
    };
    
    handleChange = ev => {
        const name = ev.target.name;
        let value = ev.target.value;
        this.setState({
            ...this.state,
            [name]: value
        }, () => {
            console.log(this.state);
        });
    };
    onSubmit = ev => {
        console.log('qwsqwswq');
        let data = Object.assign({}, this.state);
        const copy = Object.assign({}, this.state);
        let keys = Object.keys(copy);
        console.log(copy, keys);
        this.required = [];
        keys.map(info => {
            console.log(info);
            if (!copy[info])
                this.required.push(info);
        });
        console.log(this.required);
        if (this.required.length<=0)
            this.props.entityStore.post({ data: data, id: this.id });
        else {
            let plural = this.required.length > 1 ? ' are' : ' is';
            this.editBool = true;
            this.props.errorValidators(this.required, plural +  ' required when Emergency Contact ' + parseInt(this.props.emergencyNo) + ' is present');
        }
    };
    renderInfo = () => {
        var keys = Object.keys(this.state);
        this.arrayOfInfo = [];
        this.arrayOfInfo.push(<button onClick={this.onEditClick} className={'editBtn'}>{this.state && !this.editBool ? 'Edit' : 'Save'}</button>);
        keys.map((attribute, index) => {
            console.log(attribute, index, this.state[attribute]);
            this.arrayOfInfo.push(
                <div className={'eachInfo'}>
                    <p className={'label'}>{this.attributesNames[index]}: </p>
                    {this.state && !this.editBool ?
                        <span className={'labelAnswer'}>  {!this.state[attribute] ? 'Not Available' : this.state[attribute]}</span>
                        :
                        <input name={attribute} value={this.state[attribute]} onChange={this.handleChange} className={'input'} type='text' />
                    }
                </div>
            )
        });
        return this.arrayOfInfo;
    }
    render() {
        return (
            <div className={''}>
                <div>
                    <p className={'header'}>Emergency {this.props.emergencyNo} </p>
                    <div className={'cardContainer'} >
                        {this.state ? this.renderInfo():null}
                    </div>
                </div>

            </div>
        )
    }
};
decorate(Emergency, {
    editBool: observable
});
export default asEntity({ storeId: 'Emergency' })(observer(Emergency));
