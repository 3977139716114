import EntityActions from '../Actions/Entity';
import { put } from 'redux-saga/effects';

export default {
  *post(code, id, res) {
    switch (code) {
      case 200:
        yield put(EntityActions.postSucceeded(id, res.data));
        break;
      case 400:
        yield put(EntityActions.postFailed(id, res.message));
        break;
      case 422:
        yield put(EntityActions.postFailed(id, res.errors));
        break;
      case 401:
        yield put(EntityActions.postFailed(id, res.message));
        break;
      default:
    }
  },
  *get(code, id, res) {
    switch (code) {
      case 200:
        yield put(EntityActions.getSucceeded(id, res.data));
        break;
      case 400:
        yield put(EntityActions.getFailed(id, res.problem));
        break;
      case 422:
        yield put(EntityActions.getFailed(id, res.problem));
        break;
      case 401:
        yield put(EntityActions.getFailed(id, res.problem));
        break;
      default:
        yield put(EntityActions.getFailed(id, res.problem));
    }
  },
  *delete(code, id, res) {
    switch (code) {
      case 200:
        yield put(EntityActions.deleteSucceeded(id, res.data));
        break;
      case 400:
        yield put(EntityActions.deleteFailed(id, res.message));
        break;
      case 422:
        yield put(EntityActions.deleteFailed(id, res.errors));
        break;
      case 401:
        yield put(EntityActions.deleteFailed(id, res.message));
        break;
      default:
    }
  },
};
