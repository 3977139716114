import React from 'react';
import createStore from '../Redux';
import { Provider } from 'react-redux';

var store = createStore();

const WithStore = WrappedComponent => {
  return () => (
    <Provider store={store}>
      <WrappedComponent />
    </Provider>
  );
};
export default WithStore;
