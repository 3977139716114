import api from 'Services/Api';
import After from './Middleware';
import AccountActions from '../Actions/Account';
import { put, call, select } from 'redux-saga/effects';
import EntityActions from '../Actions/Entity';
var account = {};
var endpoints = {};
export default {
  *get({ id, data }) {
    account = yield select(store => store.account);
    endpoints = api(account.token, account.id);
    var res = {};
    try {
      if (id === 'ViewBranches') res = yield call(endpoints.branches.getAll);
      else if (id === 'ViewStudents') res = yield call(endpoints.students.getAll, data);
      else if (id === 'ViewAdmissions') res = yield call(endpoints.admissions.getAll, data);
      else if (id === 'Comment') res = yield call(endpoints.admissions.comment, data);
      else if (id === 'ExportExcel') {
        res = yield call(endpoints.admissions.exportExcel);
        res.data = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
      } else if (id === 'GeneratePDF') {
        res = yield call(endpoints.admissions.generatePDF, data);
        res.data = new Blob([res.data], {
          type: 'application/pdf',
        });
      }
      yield After.get(res.status, id, res);
    } catch (err) {
      yield After.get(400, id, { message: 'Connection Error' });
    }
  },
  *post({ id, data, student_id }) {
    account = yield select(store => store.account);
    endpoints = api(account.token, account.id);
    var res = {};
    try {
      if (id === 'Comment') res = yield call(endpoints.admissions.comment, data);
      else if (id === 'SpecialInfo') {
        res = yield call(endpoints.admissions.specialInfo, data);
        console.log(res);
      } else if (id === 'Physician') res = yield call(endpoints.admissions.physicianInfo, data);
      else if (id === 'Sibling') res = yield call(endpoints.admissions.siblingInfo, data);
      else if (id === 'Child') res = yield call(endpoints.admissions.childInfo, data);
      else if (id === 'Dad') res = yield call(endpoints.admissions.dadInfo, data);
      else if (id === 'Mom') res = yield call(endpoints.admissions.momInfo, data);
      else if (id === 'EditPayment') res = yield call(endpoints.admissions.editPayment, data);
      else if (id === 'Marital') res = yield call(endpoints.admissions.maritalInfo, data);
      else if (id === 'NewSibling') res = yield call(endpoints.admissions.addSibling, data);
      else if (id === 'Login') {
        res = yield call(endpoints.account.login, data);
        console.log(res);
        if (res.ok) {
          yield put(EntityActions.postSucceeded(res.data.token));
          yield put(AccountActions.setToken(res.data.token));
        } else {
          yield put(EntityActions.postFailed(id, res.data.message));
        }
      } else if (id === 'Emergency') {
        res = yield call(endpoints.admissions.emergencyInfo, data);
        if (res.ok) yield put(EntityActions.postSucceeded(res.data.token));
      }
      yield After.post(res.status, id, res);
    } catch (err) {
      yield After.post(400, id, { message: 'Connection Error' });
    }
  },
  *patch({ id, data }) {},
  *delete({ id, data }) {
    account = yield select(store => store.account);
    endpoints = api(account.token, account.id);
    var res = {};
    try {
      console.log('wefr');
      if (id === 'RemoveSibling') {
        console.log('wderf');
        res = yield call(endpoints.admissions.removeSibling, data);
        console.log(res);
      }
      yield After.delete(res.status, id, res);
    } catch (err) {
      yield After.post(400, id, { message: 'Connection Error' });
    }
  },
};
