import React from 'react';
import styles from './styles';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ViewIcon, PdfIcon } from './data';

var data = observable({});

const Actions = observer(props => {
  return (
    <div style={styles.actions}>
      <div style={styles.margin}>
        <Link
          to={{
            pathname: `/Edit_Admission/${props.student.id}`,
          }}
        >
          <div style={styles.view_link}>
            <img width='20' height='20' alt='EYE' src={ViewIcon} />
            <span style={styles.margin}>View</span>
          </div>
        </Link>
      </div>
      <div style={styles.margin}>
        <div style={styles.pdf_button} onClick={() => props.exportPDF(props.student)}>
          <img width='20' height='20' alt='PDF' src={PdfIcon} />
        </div>
      </div>
    </div>
  );
});
export default Actions;
