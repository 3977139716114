const styles = expanded => {
  return {
    root: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    header: {
      backgroundColor: 'white',
      top: 0,
      right: 0,
      left: expanded ? '250px' : '75px',
      height: '60px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'fixed',
      zIndex: 10000,
      transition: 'linear 500ms',
    },
    content: {
      backgroundColor: '#f3f3f3',
      paddingTop: '65px',
      marginLeft: expanded ? '250px' : '75px',
      // height: '100%',
      // width: '90%',
      transition: 'linear 500ms',
    },
    footer: {
      backgroundColor: '#f3f3f3',
      bottom: 0,
      right: 0,
      left: expanded ? '250px' : '75px',
      height: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'fixed',
      zIndex: 10000,
      transition: 'linear 500ms',
    },
  };
};
const year = {
  fontWeight: 700,
  marginLeft: 15,
};
const sidebar = expanded => {
  return expanded
    ? {
        backgroundColor: '#30426a',
        width: '250px',
        transition: 'linear 500ms',
        zIndex: 20000,
        fontSize: '16px',
      }
    : { backgroundColor: '#30426a', width: '75px', transition: 'linear 500ms', zIndex: 20000, fontSize: 0 };
};

export { styles, year, sidebar };
