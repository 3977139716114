import axios from 'axios';
import { create } from 'apisauce';
import ApiConfigs from 'Configs/Api';

const axiosInstance = axios.create({
  baseURL: ApiConfigs.apiUrl,
  timeout: ApiConfigs.timeout,
  headers: {
    Accept: 'application / json, text/ plain, */*',
    'Content-Type': 'application/json',
  },
});

const Api = create({ axiosInstance: axiosInstance });
function postData(url, data, token) {
  var headers = token ? { headers: { authorization: `Bearer ${token}` } } : {};
  return Api.post(url, data, headers);
}
function deleteData(url, data, token) {
  var headers = token ? { headers: { authorization: `Bearer ${token}` } } : {};
  return Api.delete(url, {}, headers);
}
function fetchData(url, token) {
  var headers = token ? { headers: { authorization: `Bearer ${token}` } } : {};
  return Api.get(url, {}, headers);
}
function endpoints(token, user_id) {
  let editURL = 'admin/admission/edit/';
  return {
    account: {
      login: data => postData('admin/login', data),
    },
    admissions: {
      comment: ({ data, id }) => {
        console.log(id, data);
        return postData(editURL + 'comment/' + id, data, token);
      },
      specialInfo: ({ data, id }) => {
        console.log(data, id);
        return postData(editURL + 'special-info/' + id, data, token);
      },
      physicianInfo: ({ data, id }) => {
        console.log(data, id);
        return postData(editURL + 'physician-info/' + id, data, token);
      },
      childInfo: ({ data, id }) => {
        console.log(data, id);
        return postData(editURL + 'child-info/' + id, data, token);
      },
      maritalInfo: ({ data, id }) => {
        console.log(data, id);
        return postData(editURL + 'marital-info/' + id, data, token);
      },
      dadInfo: ({ data, id }) => {
        console.log(data, id);
        return postData(editURL + 'dad-info/' + id, data, token);
      },
      momInfo: ({ data, id }) => {
        console.log(data, id);
        return postData(editURL + 'mom-info/' + id, data, token);
      },
      siblingInfo: ({ data, id }) => {
        console.log(data, id);
        return postData('admin/sibling/edit/' + id, data, token);
      },
      addSibling: ({ data, id }) => {
        console.log(data, id);
        return postData('admin/sibling/new/' + id, data, token);
      },
      removeSibling: ({ id }) => {
        console.log(id, token);
        return deleteData('admin/sibling/delete/' + id, null, token);
      },
      emergencyInfo: ({ data, id }) => {
        console.log(data, id);
        return postData('admin/emergency/edit/' + id, data, token);
      },
      getAll: id => {
        console.log(id);
        return fetchData('admin/admission/' + id, token);
      },
      editPayment: ({ id }) => postData(`admin/admission/edit/payment/${id}`, {}, token),
      exportExcel: () => {
        return axios.request({
          responseType: 'arraybuffer',
          url: `${ApiConfigs.apiUrl}/admin/admission/export-to-excel`,
          method: 'get',
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
      },
      generatePDF: ({ id }) => {
        return axios.request({
          responseType: 'arraybuffer',
          url: `${ApiConfigs.apiUrl}/admin/admission/generate-pdf/${id}`,
          method: 'get',
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
      },
    },
    branches: {
      getAll: () => fetchData('web/branch'),
    },
    students: {
      getAll: ({ page, branch }) => fetchData(`admin/admission/?page=${page}&branch=${branch}`, token),
    },
  };
}
export default endpoints;
