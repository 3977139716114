import { decorate, observable } from 'mobx';
import React from 'react';
import { observer } from 'mobx-react';
import { asEntity } from 'Hoc';
class Mom extends React.Component {
    info = {};
    editBool = false;
    id;
    required = [];
    notReqruied = ['MomGraduatedFrom', 'MomOccupation', 'MomWorkNumber']
    attributesNames = ['First name', 'Middle name', 'Last name', 'Mobile number', 'Email', 'Graduated from','occupation','Work number']
    constructor(props) {
        super(props);
        this.state = {};
    }
    onEditClick = () => {
        this.editBool = !this.editBool;
        if (!this.editBool) this.onSubmit();
    };
    entityDidCatch(message) {
        this.editBool = true;

        this.props.displayMessage('validationError', message);
    };
    entityDidPosted = (data) => {
        this.editBool = false;

        this.props.editSuccess('Mom information');
        console.log(data);
    };
    getInfoObject() {
        console.log(this.info);
        var keys = Object.keys(this.props.momInfo);
        let object = {};
        keys.map(key => {
            object[key] = this.props.momInfo[key];
        });
        let data = {};
        data['MomFirstName'] = object.mom_first_name;
        data['MomMiddleName'] = object.mom_middle_name;
        data['MomLastName'] = object.mom_last_name;
        data['MomMobileNumber'] = object.mom_mobile_number;
        data['MomEmail'] = object.mom_email;
        data['MomGraduatedFrom'] = object.mom_graduated_from;
        data['MomOccupation'] = object.mom_occupation;
        data['MomWorkNumber'] = object.mom_work_number;
        console.log(data);
        return data;
    };
    componentDidMount = props => {
        this.id = this.props.id;
        console.log(this.id);
        this.info = this.getInfoObject();
        this.setState({
            ...this.info
        }, () => {
            console.log(this.state);
        });
    };
    handleChange = (ev,required) => {
        const name = ev.target.name;
        let value = ev.target.value;
        this.setState({
            ...this.state,
            [name]: value
        }, () => {
            console.log(this.state);
        });
    };
    onSubmit = ev => {
        console.log('qwsqwswq');
        let data = Object.assign({}, this.state);
        const copy = Object.assign({}, this.state);
        let keys = Object.keys(copy);
        console.log(copy, keys);
        this.required = [];
        keys.map(info => {
            console.log(info);
            if (!copy[info] && this.notReqruied.indexOf(info) == -1)
                this.required.push(info);
            else if (!copy[info]) data[info] = null;
        });
        console.log(this.required);
        if (this.required.length <= 0)
            this.props.entityStore.post({ data: data, id: this.id });
        else{
            this.editBool = true;

            let plural = this.required.length > 1 ? ' are' : ' is';
            this.props.errorValidators(this.required, plural +  ' required when Mom is present');
        }
    }
    renderInfo = () => {
        var keys = Object.keys(this.state);
        this.arrayOfInfo = [];
        this.arrayOfInfo.push(<button onClick={this.onEditClick} className={'editBtn'}>{this.state && !this.editBool ? 'Edit' : 'Save'}</button>);
        keys.map((attribute, index) => {
            console.log(attribute, index, this.state[attribute]);
            this.arrayOfInfo.push(
                <div className={'eachInfo'}>
                    <p className={'label'}>{this.attributesNames[index]}: </p>
                    {this.state && !this.editBool ?
                        <span className={'labelAnswer'}>  {!this.state[attribute] ? 'Not Available' : this.state[attribute]}</span>
                        :
                        <input name={attribute} value={this.state[attribute]} onChange={this.handleChange} className={'input'} type='text' />
                    }
                </div>
            )
        });
        return this.arrayOfInfo;
    }
    render() {

        return (
            <div className={'cardComponent'}>
                <div>
                    <p className={'header'}>Mom information</p>
                    <div className={'cardContainer'} >
                        {this.state ? this.renderInfo():null}
                    </div>
                </div>
            </div>
        )
    }
};
decorate(Mom, {
    editBool: observable
});
export default asEntity({ storeId: 'Mom' })(observer(Mom));
