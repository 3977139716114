import React from 'react';

function ErrorPage() {
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <h1 style={{ fontSize: '150px' }}>404</h1>
        <div>OOPS! Something went wrong here</div>
      </div>
    </div>
  );
}
export default ErrorPage;
