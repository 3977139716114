import React, { useEffect, useState } from 'react';
import SideBar from 'Components/Layout/SideBar';
import Navbar from 'Components/Layout/Header';
import { styles, year, sidebar } from './styles';

export default function Layout({ children }) {
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    reportWindowSize();
    window.addEventListener('resize', reportWindowSize);

    return () => {
      window.removeEventListener('resize', reportWindowSize);
    };
  }, []);

  function reportWindowSize() {
    if (window.innerWidth < 987)  setExpanded(false);
    else setExpanded(true);
  }
  function changeMenu() {
    setExpanded(!expanded);
  }

  return (
    <div style={styles.root}>
      <div>
        <SideBar style={sidebar(expanded)} changeMenu={changeMenu} expanded={expanded} />
      </div>
      <div style={styles(expanded).header}>
        <Navbar changeMenu={changeMenu} />
      </div>
      <div style={styles(expanded).content}> {children}</div>
      <div style={styles(expanded).footer}>
        <span style={year}>{new Date().getFullYear()}</span>
      </div>
    </div>
  );
}
