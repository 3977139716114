import React from 'react';
import { connect } from 'react-redux';
import AccountActions from 'Redux/Actions/Account';
import { Dropdown } from 'semantic-ui-react';
import styles from './styles';

const Avatar = require('Assets/Avatar/boy.svg');
const Trigger = <img alt='Avatar' style={styles.img} src={Avatar} htmlFor='cars' />;

function Navbar(props) {
  var path = window.location.pathname.split('/');

  function logout() {
    props.logout();
  }
  function profile() {
    alert('profile');
  }
  const options = [
    { key: 'user', text: 'Profile', icon: 'user', event: profile },
    { key: 'sign-out', text: 'Log Out', icon: 'sign out', event: logout },
  ];

  return (
    <>
      <div style={styles.wrapper}>
        <div style={styles.h1}>{'View Admissions'}</div>
        <div style={styles.h2}>
          Dashboard / {path[path.length - 1] === 'admissions' ? 'Admissions' : 'Edit Admissions'}
        </div>
      </div>
      <div>
        <Dropdown trigger={Trigger} pointing='top right' icon={null}>
          <Dropdown.Menu>
            <Dropdown.Header content='admin@trilliumpreschool.com' />
            {options.map(option => (
              <Dropdown.Item key={option.key} text={option.text} icon={option.icon} onClick={option.event} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}

const mapStateToProps = store => ({
  account: store.account,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(AccountActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
